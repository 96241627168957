<script>
export default {
    data(){
        return {
            representationType: '',
            representativeSecond: false,
        }
    },
    props:{
        initialRepresentationType: 'user_responsible'
    },
    beforeMount() {
        this.representationType = this.initialRepresentationType;

        const savedRepresentativeSecond = sessionStorage.getItem('representativeSecond');
        if (savedRepresentativeSecond !== null) {
            this.representativeSecond = JSON.parse(savedRepresentativeSecond);
        }

        const savedRepresentationType = sessionStorage.getItem('representationType');
        if (savedRepresentationType) {
            this.representationType = savedRepresentationType;
        }
    },
    watch: {
        representativeSecond(newVal) {
            sessionStorage.setItem('representativeSecond', JSON.stringify(newVal));
        },

        representationType(newVal) {
            sessionStorage.setItem('representationType', newVal);
        }
    },
    beforeDestroy() {
        sessionStorage.removeItem('representativeSecond');
        sessionStorage.removeItem('representationType');
    }
}
</script>